<template>
    <section>
        <div class="row d-flex a-center j-center ">
            <i class="icon-arrow-left position-absolute f-25 cr-pointer" style="left:32px" @click="$emit('init')" />
            <div class="my-3 f-600 f-20">
                Zonas de cobertura
            </div>
        </div>
        <mapa-google
        ref="GoogleMaps"
        :accion="'nuevo'"
        :coordinates="coordinates"
        height="70vh"
        :zoom="13"
        />
    </section>
</template>
<script>
import axios from 'axios'

export default {
    data(){
        return {
            valid: false,
            newDireccion: false,
            loading: false,
            direcciones: [],
            query: '',
            place: null,
            direccion: null,
            icono: '/img/icons/marker_cliente.svg',

            coordinates: {
                lat: 7.11392,
                lng: -73.1198,
            },
        }
    },
    mounted(){
        this.getGeocercas()
    },
    methods: {
        async getGeocercas(){
            try {
                
                const {data} = await axios('/zonas-cobertura')
                console.log(data);
                this.drawGeocercasGrupo(data.geocercas)

            } catch (error){
                this.errorCatch(error)
            }
        },
        async drawGeocercasGrupo(geocercas){
            setTimeout(() => {
                this.$refs.GoogleMaps.drawGeocercas(geocercas)
            }, 500);
        },
        

       
       
    }
}
</script>